import React, { useState, useEffect } from "react";
import RouteComponent from "./containers/RouteComponent";
import { Context } from "./Context";
import "./css/bootstrap.css";
import "./css/App.css";

export default function App() {
	const INITIAL = {
		words: [
			{
				id: "",
				category: "",
				question: "",
				answers: [""],
			},
		],
		categories: [
			{
				id: "",
				name: "",
				count: "",
				isSelected: false,
			},
		],
		selectedCategory: null,
		token: {
			value: "",
			expireAt: "",
		},
		isLogin: false,
	};

	const [state, setState] = useState(INITIAL);

	const initializeState = (data, isLoginSuccessful) =>
		setState({
			...state,
			token: { value: data.data?.token, expireAt: data.data?.expireInMinutes },
			isLogin: isLoginSuccessful,
		});

	const loginHandler = (arg) => setState({ ...state, isLogin: arg });

	const selectCategory = (category) =>
		setState({ ...state, selectedCategory: category });

	const fillCategories = (arg) => {
		return setState({
			...state,
			categories: arg.map((each) => ({
				id: each.id,
				name: each.name,
				count: each.count,
				isSelected: false,
			})),
		});
	};

	const deleteCategory = (categoryId) =>
		setState({
			...state,
			categories: state.categories.filter((each) => each.id !== categoryId),
		});

	useEffect(() => {
		const tokenExpire = localStorage.getItem("token-expiration");
		const now = (Date.now() / 1000).toFixed();
		if (tokenExpire > now) {
			initializeState(
				{ token: localStorage.getItem("token-value"), expireAt: tokenExpire },
				true
			);
		}
	}, []);

	return (
		<Context.Provider
			value={{
				state,
				initializeState,
				loginHandler,
				fillCategories,
				selectCategory,
				deleteCategory,
			}}
		>
			<div className="cnt">
				<RouteComponent />
			</div>
		</Context.Provider>
	);
}
